import React from 'react'
import styled, { css } from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

// Elements
import ParseContent from 'components/shared/ParseContent'

// Images
import Circle from 'img/circlelist.svg'

// Interface
import { TextProps } from 'components/flex/Text/TextShell'

const Section = styled.section<{ bgColor?: string }>`
  ${(props) =>
    props.bgColor === 'grey' &&
    css`
      background-color: ${({ theme }) => theme.color.grey};
    `}
`

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    margin-bottom: 1.5rem;
    padding-right: 3rem;
  }

  & ul {
    list-style: none;
    padding-left: 1rem;
    & li {
      position: relative;
      margin-left: 0.25rem;
      margin-bottom: 1rem;
      font-size: ${({ theme }) => theme.font.size.big};
      &:before {
        position: absolute;
        left: -20px;
        top: 0;
        content: url(${Circle});
        width: 14px;
        height: 14px;
      }
    }
  }

  @media (min-width: 576px) {
    & p {
      line-height: ${({ theme }) => theme.font.size.larger};
    }
  }
`

const ImageWrapper = styled.div<{ isAbsolute?: boolean }>`
  @media (min-width: 1200px) {
    ${(props) =>
      props.isAbsolute &&
      css`
        position: absolute;
        bottom: -195px;
        right: -300px;
      `}
  }

  @media (max-width: 1399px) {
    ${(props) =>
      props.isAbsolute &&
      css`
        width: 685px;
      `}
  }

  @media (max-width: 991px) {
    width: 100%;
    height: 100%;
  }
`

const Image = styled(Plaatjie)`
  @media (max-width: 575.98px) {
    max-width: 250px;
  }
`

const TextImage: React.FC<TextProps> = ({ fields }) => (
  <Section
    className="position-relative py-lg-5"
    bgColor={fields.options?.bgcolor}
  >
    <div className="container pt-3 pb-lg-5">
      <div className="row justify-content-center pb-5">
        <div className="col-lg-11">
          <div className="row position-relative">
            <div
              className={`col-sm-4 col-md-6 mt-5 mt-lg-0 col-xl-${
                fields.options?.columnwidth && 12 - fields.options.columnwidth
              } ${
                fields.options?.alignment === 'left'
                  ? 'order-sm-2'
                  : 'order-sm-1'
              } mb-5 mb-sm-0`}
            >
              <ImageWrapper
                className="text-center"
                isAbsolute={fields.options?.imageposition === 'overflow'}
              >
                <Image image={fields.image} alt="" />
              </ImageWrapper>
            </div>
            <div
              className={`col-sm-8 col-md-6 col-xl-${
                fields.options?.columnwidth
              }
               ${
                 fields.options?.alignment === 'left'
                   ? 'order-sm-1'
                   : 'order-sm-2 ps-lg-5'
               }`}
            >
              <Content content={fields.description} className="pe-lg-5" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Section>
)

export default TextImage
